$box-shadow: 1px 0px 5px 0px rgba($gray, 1);
html,
body {
	width: 100%;
	height: 100%;
	font-family: $font;
	font-size: 13px;
	background-color: $white;
	&::-webkit-scrollbar {
		width: 13px;
	}
	/* Track */
	&::-webkit-scrollbar-track {
		background: $gray;
	}
	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: $theme;
	}
	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: darken($theme, 5%);
	}
}

h1 {
	color : #2B3674;
}

h2 {
	color: #2B3674;
}

h4 {
	color: #2B3674;
}

#root,
.page-container,
.content {
	height: 100%;
}

.content {
	overflow: auto;
}

// TEMP REMOVE CAUSE APEX CHART WIDTH ISSUE
// .content {
//     transition: all 250ms cubic-bezier(0, 1.2, 1, 1);
// }
.header {
	@include box-shadow(0px 0px 5px 0px rgba($gray, 0.75));
}

.breadcrumb .breadcrumb-item {
	cursor: default;
}

.page-header {
	display: flex;
	align-items: center;
	font-size: 1.4rem;
	margin-bottom: 1.0rem;
	color : $smalltitle;
}

.page-subheader {
	display: flex;
	align-items: center;
	font-size: 1.4rem;
	margin-bottom: 0.2rem;
	color : $smalltitle;
}


.title-blue {
	color : #0099FF;
	font-size: 2.2rem;
}

.title-green {
	color : #00C242;
	font-size: 2.2rem;
}

.title-red {
	color : #EA0000;
	font-size: 2.2rem;
}

.title-orange {
	color : #FF8616;
	font-size: 2.2rem;
}

.widget {
	&.widget-stats {
		color: $white;
		.stats-title,
		.stats-desc {
			color: inherit;
		}
		.stats-progress {
			background: $black;
			.progress-bar {
				background: $white;
			}
		}
	}
}

.card {
	// @include box-shadow(1px 1px 5px 0px rgba($black, 0.35));
	@include box-shadow(0 0 1.25rem rgba(31, 45, 61, 0.08));
	border: none;
	&.card-theme {
		@include box-shadow(1px 1px 5px 0px rgba($theme, 0.35));
		background-color: $theme;
		color: $white;
		border: none;
	}
	.card-body {
		padding: 15px 20px;
		box-shadow: 0px 5px 5px -2px rgba(122,122,122,0.75)
	}
	.card-header {
		background-color: $white;
		font-weight: bold;
		font-size: 120%;
		border-color: rgb(242, 242, 242);
	}
	.card-footer {
		background-color: $white;
	}
}

.referral-credit-amt {
	font-size: 3rem;
}

.referral-credit-smallnote {
	font-style: italic;
}

.content.has-footer {
	padding-bottom: 75px;
}

.text-bold {
	font-family: $font-bold;
	font-weight: bold;
}

.text-theme {
	color: $theme;
}

.text-pending {
	color: $orange;
}

.text-blue {
	color: $blue;
}

.text-pink {
	color: $pink;
}

.text-status {
	font-family: $font-bold;
	&.active {
		color: $green;
	}
	&.inactive {
		color: $red;
	}
	&.pending {
		color: $orange;
	}
}

.pointer-cursor {
	cursor: pointer;
}

.form-footer {
	display: flex;
	position: fixed;
	bottom: 0;
	left: 0;
	width: calc(100% - 230px);
	margin-left: 230px;
	padding: 10px 15px;
	justify-content: space-between;
	background-color: darken($white, 2%);
	@include box-shadow(0px 0px 5px 0px rgba($gray, 0.75));
	&.sidebar-minify {
		width: calc(100% - 60px);
		margin-left: 60px;
	}
}

.tabs {
	// margin-bottom: 15px;
	.tab-item {
		height: 60px;
		text-align: center;
		padding: 15px 20px;
		cursor: pointer;
		background-color: $white;
		font-size: 1rem;
		line-height: 1.9rem;
		border-top: 2px solid $light-gray;
		border-bottom: 2px solid $light-gray;
		transition: all 0.3s;
		&.active {
			border-bottom: 2px solid $theme;
		}
		&:hover {
			font-size: 1.1rem;
			line-height: 1.8rem;
			background-color: $secondary-white;
			transition: all 0.3s;
		}
		&.n-b-t {
			border-top: none;
		}
		&.n-b-b {
			border-bottom: none;
		}
	}
	div {
		&:not(:first-child) {
			padding-left: 0px;
		}
		&:not(:last-child) {
			border-right: 1px solid $light-gray;
			padding-right: 0px;
		}
	}
}

.btn-float-right {
	float: right;
	margin-top: -5px;
}

.radio.radio-css {
	user-select: none;
	/* supported by Chrome and Opera */
	-webkit-user-select: none;
	/* Safari */
	-khtml-user-select: none;
	/* Konqueror HTML */
	-moz-user-select: none;
	/* Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	label {
		cursor: pointer;
		padding-left: 35px;
		font-size: 16px;
		line-height: 19px;
		margin-right: 10px;
	}
	label:before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 18px;
		height: 18px;
		background: $light-gray;
		border-radius: 25px;
		transition: all 0.3s;
	}
	input:checked {
		+ label:before {
			background: $theme;
			border-color: $theme;
			transition: all 0.3s;
		}
		+ label:after {
			content: "";
			position: absolute;
			// top: 7px;
			// left: 7px;
			width: 8px;
			height: 8px;
			background: $white;
			border-radius: 6px;
			transition: all 0.3s;
		}
	}
}

.checkbox.checkbox-css {
	user-select: none;
	/* supported by Chrome and Opera */
	-webkit-user-select: none;
	/* Safari */
	-khtml-user-select: none;
	/* Konqueror HTML */
	-moz-user-select: none;
	/* Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	label {
		cursor: pointer;
		padding-left: 23px;
		font-size: 1rem;
		line-height: 25px;
		margin-right: 1rem;
	}
	label:before {
		content: "";
		position: absolute;
		left: 0;
		top: 3px;
		width: 17px;
		height: 17px;
		background: $light-gray;
		transition: all 0.3s;
	}
	input:checked {
		+ label:before {
			background: $theme;
			border-color: $theme;
			transition: all 0.3s;
		}
		+ label:after {
			top: 3px;
			left: 0px;
			transition: all 0.3s;
			background-size: 80% 80%;
		}
	}
}

.picker-container {
	width: 245px;
	height: 245px;
	overflow: auto;
	display: flex;
	flex-wrap: wrap;
	margin: auto;
	.picker-item {
		height: 30px;
		margin: 5px;
		text-align: center;
		line-height: 30px;
		font-size: 13px;
		cursor: pointer;
		transition: all 0.2s;
		&:hover {
			background-color: $theme;
			color: $white;
			transition: background-color 0.2s, color 0.1s;
		}
		// background: $secondary-white;
		&.day {
			width: 30px;
			border-radius: 30px;
		}
		&.month {
			width: 110px;
			border-radius: 10px;
		}
		&.year {
			width: 69px;
			border-radius: 10px;
		}
	}
	&::-webkit-scrollbar {
		width: 5px;
	}
	&::-webkit-scrollbar-track {
		background: $gray;
	}
	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: $theme;
	}
	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: darken($theme, 5%);
	}
}

.filter-container {
	margin: 2rem 0 1rem;
}

.label-custom {
	padding: 5px 10px;
	font-size: 80%;
	&:not(:last-child) {
		margin-right: 5px;
	}
}

.centered {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-size: 1.2rem;
	height: 55%;
}

.simple-card {
	border: 1px solid lighten($light-gray, 5%);
	border-radius: 10px;
	padding: 15px;
	margin-top: 15px;
	.simple-card__item {
		flex: 2;
	}
	.simple-card__action {
		padding-left: 5px;
	}
}

.justify-between {
	justify-content: space-between;
}

.justify-center {
	justify-content: center;
}

.top-menu {
	.nav > li.active {
		> a {
			> span {
				color: #fff;
			}
			> i {
				color: #fff;
			}
			> .caret {
				color: #fff;
			}
		}
	}
}

.incorporation-card {
	width: "fit-content";
	min-width: 235px;
	height: 100%;
	.title {
		flex: 1;
		h4 {
			text-align: center;
		}
	}
	.content {
		height: fit-content;
		margin-left: 0;
		padding: 0px 15px;
	}
	.card-body {
		padding: 15px 0px;
	}
	.card-footer {
		height: 65px;
		background: $white;
		.inner-container {
			padding: 8px 5px;
			background: $theme;
			border-radius: 5px;
			text-align: center;
			color: $white;
			font-size: 0.8rem;
			cursor: pointer;
			transition: 0.3s background;
			&:hover {
				background: darken($theme, 5%);
				transition: 0.3s background;
			}
		}
		&.active {
			padding: 0;
			transition: padding 0.2s;
			button {
				font-size: 14px;
				padding: 20px;
				border-top-left-radius: 0;
				border-top-right-radius: 0;
				transition: padding 0.2s;
			}
		}
	}
}

#incorporationAccordion {
	.card-header {
		font-size: 14px;
		padding: 10px 15px;
	}
}

.officers-row {
	div:not(:last-child) {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	div:first-child {
		justify-content: flex-start;
	}
}

.form-step__wrapper {
	margin: 20px 20%;
	form {
		justify-content: center;
	}
	.upperTitle {
		white-space: nowrap;
	}
	.lowerTitle {
		font-size: 12px;
	}
	.rc-steps-item {
		cursor: pointer;
	}
	.rc-steps-item-sub-step {
		overflow: unset !important;
		.rc-steps-item-icon {
			width: 20px;
			height: 20px;
			padding: 2px;
		}
		.rc-steps-item-upper-title {
			font-size: 13px;
		}
		.rc-steps-item-upper-content {
			top: -48px !important;
			white-space: nowrap;
		}
	}
	.rc-steps-item-title:after {
		top: 10px;
	}
}

.navbar-shopping-cart {
	> ul {
		width: 340px;
	}
	.cart-dropdown-head {
		display: flex;
		justify-content: space-between;
		padding: 2px 10px;
		font-size: 18px;
	}
	.services-item {
		margin-bottom: 0;
		font-size: 16px;
	}
	.dropdown-item:not(:first-child) {
		padding: 10px 15px;
		border-top: 1px solid lighten($light-gray, 12%);
	}
}

.widget.widget-stats {
	background-color: #ddc990;
	.stats-info {
		h4,
		p {
			color: $black !important;
		}
	}
}

#top-menu {
	background: white;
	box-shadow: 0 3px 15px -2px #bababa;
	border-top: 1px solid lightgrey;
	border-bottom: 1px solid lightgrey;
	font-size: 14px;
	a {
		color: $black;
	}
	.nav a {
		&:hover {
			background: #f2f3f4;
		}
	}
	.nav .sub-menu {
		background: #f2f3f4;
	}
	.nav > li.active {
		& > a {
			background: $theme !important;
		}
		.sub-menu {
			li.active a {
				background: #d8d8d8;
			}
		}
	}
}

body {
	label {
		font-size: 14px;
	}
	table {
		th {
			font-size: 14px;
		}
		tbody {
			font-size: 13px;
		}
	}
}

.cards-list {
	z-index: 0;
	width: 100%;
	display: flex;
	// justify-content: space-around;
	flex-wrap: wrap;
	.card {
		// margin: 30px auto;
		width: 300px;
		// height: 300px;
		border-radius: 7px;
		box-shadow: 2px 2px 10px 7px rgba(0, 0, 0, 0.25), -2px -2px 10px 7px rgba(0, 0, 0, 0.22);
		cursor: pointer;
		transition: 0.4s;
	}
	.card .card_image {
		width: inherit;
		height: inherit;
		border-radius: inherit;
	}
	.card .card_image img {
		width: inherit;
		height: inherit;
		border-radius: inherit;
		// object-fit: cover;
	}
	.card .card_title {
		text-align: center;
		border-radius: 0px 0px 40px 40px;
		font-family: sans-serif;
		font-weight: bold;
		font-size: 30px;
		// margin-top: -80px;
		height: 40px;
	}
	.card:hover {
		transform: scale(0.9, 0.9);
		box-shadow: 2px 2px 10px 7px rgba(0, 0, 0, 0.25), -2px -2px 10px 7px rgba(0, 0, 0, 0.22);
	}
}

#hierarchyForm {
	overflow-x: auto;
	th {
		width: 1%;
		white-space: nowrap;
	}
	td:not(:last-child) {
		min-width: 150px;
	}
	td:last-child {
		min-width: 250px;
	}
}

.hierarchy-modal {
	width: fit-content;
	max-width: 80%;
}

.nowrap {
	white-space: nowrap;
}

.badge {
	padding: 0.4rem 0.8rem;
	&.badge-success {
		color: #1ec51b;
		background-color: #deffe3;
	}
	&.badge-warning {
		color: #ffb014;
		background-color: #fff3db;
	}
	&.badge-danger {
		color: #f64e60;
		background-color: #ffe2e5;
	}
	&.badge-info {
		color: #3699ff;
		background-color: #e1f0ff;
	}
}

ul.list-description {
	list-style-type: none;
	margin-left: 0;
	padding-left: 0;
	line-height: 1;
	margin-bottom: 0;
	li {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-bottom: 0.2rem;
		.text-small {
			font-size: 70%;
		}
	}
}

.icon-rounded {
	border-radius: 50px;
}

.namecheck-wrapper {
	line-height: 1.2;
	div {
		display: inline;
	}
}

.d-flex-center {
	display: flex;
	align-items: center;
}

.justify-between {
	justify-content: space-between;
}

.ul-clean {
	margin-left: 0;
	margin-bottom: 0;
	padding-left: 0;
	list-style-type: none;
}

.elevated {
	transition: all 250ms ease-out;
	&:hover {
		box-shadow: none;
		cursor: pointer;
	}
}

.non-elevated {
	box-shadow: none;
}

.label-chkbox {
	display: flex;
	align-items: center;
	line-height: 1.2;
}

.navbar-icon {
	padding: 8px;
	margin-right: 0.2rem;
	font-size: 23px;
}

.listing-wrapper {
	overflow-y: auto;
	list-style-type: none;
	padding-left: 0;
	box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.08);
	border-radius: 6px;
	.card {
		transition: all 250ms ease-in;
		margin-bottom: 0.5rem;
		&:hover {
			box-shadow: unset;
		}
	}
	li {
		border-bottom: 1px solid #f2f2f2;
		padding: 1rem;
		&:hover:not(.listing-header) {
			background: #f9f9f9;
			cursor: pointer;
		}
		&.no-result {
			padding: 3rem;
			text-align: center;
		}
	}
}

.notiflix-confirm-buttons {
	.confirm-button-ok {
		float: right !important;
		margin: 0 0 0 2% !important;
	}
	.confirm-button-cancel {
		float: left !important;
		margin: 0 2% 0 0 !important;
	}
}

.notiflix-report {
	.notiflix-report-overlay {
		background: #00000045 !important;
	}
	.notiflix-report-content {
		background: #fff;
	}
}

.hierarchy-btn-add {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	min-width: 200px;
	margin-left: 10px;
	border: 1px solid #ccc;
	cursor: pointer;
	transition: all 0.3s;
	border-radius: 10px;
	&:hover {
		background: $gray;
		color: $white;
		border-color: $gray;
		transition: background 0.3s;
	}
	&:first-child {
		margin-bottom: 20px;
	}
}

.hierarchy-form-container {
	display: flex;
	overflow: auto;
	.hierarchy-form-item {
		position: relative;
		width: 260px;
		padding-left: 10px;
		padding-right: 10px;
		padding-bottom: 140px;
		> div {
			padding-bottom: 10px;
		}
		&:not(:first-child) {
			border-left: 1px solid $light-gray;
		}
		.fileContainer {
			margin-top: 5px;
			max-width: 260px;
			padding: 0;
			> p {
				display: none;
			}
			> button {
				margin: 0;
				width: 100%;
				border-radius: 5px;
			}
		}
		.hierarchy-officer-shares {
			position: absolute;
			width: 93%;
			bottom: 0;
			> button {
				width: 100%;
				margin-top: 20px;
				&.opacity-none {
					opacity: 0;
				}
			}
		}
	}
}

.empty__input {
	label {
		color: #ff5050;
	}
}

.messages__error {
	color: #ff5050;
}

.title-divider {
	width: 20px;
	background-color: #000;
	margin: 0.5rem 0 1rem 0;
	height: 3px;
}

.full-height {
	height: 100%;
}

.incorporation-footer {
	position: fixed;
	display: flex;
	flex-direction: row;
	padding: 10px;
	bottom: 0;
	width: 80vw;
	justify-content: space-between;
	margin-bottom: 10px;
}

.advFilterPopover {
	> div {
		min-width: 45%;
		box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	}
}

.pagination {
	& .page-item {
		& + .page-item {
			margin-left: 0px;
		}
		&.active {
			.material-ui-paginate-btn {
				border: 1px solid gray;
				background-color: #f2f3f4;
			}
		}
	}
	.material-ui-paginate-btn {
		color: rgba(0, 0, 0, 0.87);
		height: 32px;
		margin: 0 3px;
		padding: 8px;
		font-size: 0.875rem;
		min-width: 32px;
		box-sizing: border-box;
		text-align: center;
		font-family: "Roboto", "Helvetica", "Arial", sans-serif;
		font-weight: 400;
		line-height: 1.43;
		border-radius: 16px !important;
		letter-spacing: 0.01071em;
		border: 1px solid lightgray;
		&:hover {
			background-color: #f5f5f5;
		}
	}
}

.widget-content {
	padding: 1rem;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-align: center;
	align-items: center;
	> .widget-content-wrapper {
		display: -ms-flexbox;
		display: flex;
		-ms-flex: 1;
		flex: 1;
		position: relative;
		-ms-flex-align: center;
		align-items: center;
	}
}

table.company-officer-profile-detail {
	tbody {
		font-size: 14px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		& tr {
			& td {
				&.address-field {
					text-overflow: ellipsis;
					overflow: hidden;
				}
				max-width: 200px;
				width: 25%;
				min-width: 25%;
				&.text-label {
					font-weight: bold;
				}
				padding: 1px;
			}
		}
	}
}

a.link-style-1 {
	text-decoration: none;
	font-size: 13px;
	color: #000;
	font-weight: 300;
	margin: 0 2px;
	position: relative;
	&:before {
		position: absolute;
		margin: 0 auto;
		top: 100%;
		left: 0;
		width: 100%;
		height: 2px;
		background-color: #000;
		content: "";
		opacity: 0.3;
		-webkit-transform: scaleX(0.9);
		transition-property: opacity, -webkit-transform;
		transition-duration: 0.3s;
	}
	&:hover:before {
		opacity: 1;
		-webkit-transform: scaleX(1);
	}
}

.sidebar-right {
	left: unset;
	right: 0;
}

.navbar-nav li:hover {
	cursor: pointer;
}

.navbar-nav li,
.navbar-nav li a {
	display: flex !important;
	justify-content: center;
	align-items: center;
}

.navbar-nav li i {
	font-size: 22px !important;
}

#projectsTable .label {
	font-size: 90%;
}

.project-quotation-items-check {
	cursor: pointer;

	.row {
		.col,
		[class^="col-"],
		.flex-center {
			div:first-child {
				font-weight: 700;
			}

			i {
				font-size: 30px;
			}

			.icon-selected {
				color: $theme;
			}
		}
	}

	&:not(:last-child) {
		padding-bottom: 15px;
		margin-bottom: 15px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}

	.description {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2; /* number of lines to show */
		-webkit-box-orient: vertical;
	}

	h3 {
		margin-bottom: 0px;
	}
}

.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.scrollable-container {
	overflow: auto;

	&::-webkit-scrollbar {
		width: 5px;
	}

	&::-webkit-scrollbar-track {
		background-color: $white;
	}

	&::-webkit-scrollbar-thumb {
		background: $theme;
		border-radius: 5px;
	}
}

#tasksTable {
	td {
		height: 40px;
	}

	th:first-child {
		.table-checkbox {
			.checkmark {
				top: -24px;
			}
		}
	}
}

.react-datepicker-wrapper {
	display: block;
	width: 100%;
}

#datepicker-portal {
	.react-datepicker-popper {
		z-index: 1051;
	}
}

.custom-status-label {
	display: block;
	max-width: 80px;
}

.label-priority {
	width: 80px;
	display: block;
	padding: 3px 6px 4px;
	font-size: 90%;
	font-weight: 600;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	border-radius: 4px;

	&.label-green {
		background-color: $green;
	}

	&.label-yellow {
		background-color: $yellow;
		color: black;
	}

	&.label-orange {
		background-color: $orange;
	}

	&.label-red {
		background-color: $red;
	}
}

.daterangepicker-container {
	display: inline-block;
	background: #fff;
	border: 1px solid #ccc;
	padding: 10px;
	border-radius: 4px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
.selected-dates {
	margin-top: 10px;
	font-size: 14px;
}
  
.selected-dates p {
	margin: 0;
	padding: 2px 0;
}

.dashboard-item {
    > .item-title {
        font-size: 35px;
        font-weight: bold;

        &.pink > span {
            color: #ff0066;
        }

        &.blue > span {
            color: #0099ff;
        }

        &.yellow > span {
            color: #cccc00;
        }

        &.purple > span {
            color: #cc33ff;
        }
    }

    > .item-date {
        color: #999;
        font-size: 11px;
        margin-bottom: 20px;
    }

    > img {
        width: 100%;
        border-radius: 10px;
        height: 150px;
        margin-bottom: 30px;
    }   

    > .item-tag-container {
        padding: 5px;
        background-color: black;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;

        > .item-tag {
            width: 15px;
            height: 40px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;

            &.pink {
                background-color: #ff0066;
            }

            &.blue {
                background-color: #0099ff;
            }

            &.yellow {
                background-color: #cccc00;
            }

            &.purple {
                background-color: #cc33ff;
            }
        }
        
        > .item-tag-title {
            text-align: center;
            flex: 1;
            color: white;
            font-size: 16px;
        }
    }
}

.btn-table{
    display: inline-flex;
    height: 100%;
    aspect-ratio: 1;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s;
    border-left: 1px solid #ccc;

    &:hover {
        background-color: #0099ff;
        color: white;
        transition: all 0.2s;
    }

    &.last-btn {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}