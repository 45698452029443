.task-container {
	display: flex;
	flex-direction: row;
	margin-bottom: 8px;

	.task-icon {
		display: flex;
		align-items: center;
		margin-left: 10px;
		margin-right: 10px;

		i {
			transform: rotate(90deg);
		}
	}

	.card {
		flex: 1;
		padding: 10px 15px 5px;
	}
}

.department-list {
	.list-item,
	.inner-list-item {
		padding: 10px 15px;
		cursor: pointer;
		border-bottom: 1px solid $lighter-gray;

		i {
			width: 16px;
			height: 16px;
			color: $light-gray;
			font-size: 16px;
			margin-right: 10px;

			&.active {
				color: $green;
			}
		}

		&:hover {
			background-color: $secondary-white;
		}
	}

	.inner-list-item {
		padding: 10px 15px 10px 40px;
	}

	.list-item-title {
		font-weight: bold;
	}
}

.task-modal {
	display: flex;
	flex-direction: row;
	height: 40vh;

	> div {
		flex: 1;

		&:not(:last-child) {
			border-right: 1px solid $lighter-gray;
		}
	}
}

.section-title {
	padding: 15px 15px 5px;
	color: $theme;

	span {
		color: $gray;
	}
}

.task-group-card {
	cursor: pointer;
	transition: box-shadow 0.5s;

	&:hover {
		box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.25);
	}

	.task-group-row {
		display: flex;
		flex-direction: row;
		align-items: center;

		> i {
			width: 16px;
			height: 16px;
			color: $light-gray;
			font-size: 16px;
			margin-right: 10px;
		}

		.task-group-name {
			display: flex;
			align-items: center;
			font-size: 16px;
			font-weight: bold;
			flex: 2;
		}

		.label {
			min-width: 90px;
			font-size: 90%;
			margin-right: 10%;
		}

		.badge {
			min-width: 100px;
			font-size: 90%;
			margin: 0px 2.5px;

			&:not(:last-child) {
				margin-right: 10px;
			}
		}
	}
}

.task-row {
	display: flex;
	flex-direction: row;
	align-items: center;

	> i {
		display: flex;
		align-items: center;
		color: $light-gray;
		font-size: 16px;
		margin-left: 25px;
		margin-right: 12px;
		transform: rotate(90deg);
	}

	.task-details-row {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.task-name {
		display: flex;
		align-items: center;
		font-size: 14px;
		font-weight: bold;
		flex: 1;

		span {
			margin-left: 15px;
		}
	}

	.task-desc {
		flex: 1;
	}

	.task-duration {
		flex: 1;
	}

	.task-card {
		border-radius: 0;
	}

	&:first-child {
		i {
			opacity: 0;
		}

		button {
			opacity: 0;

			&:hover {
				cursor: default;
			}
		}

		.task-card {
			background-color: transparent;
			box-shadow: none;
		}
	}

	&:nth-child(2) {
		.task-card {
			border-top-left-radius: 6px;
			border-top-right-radius: 6px;
		}
	}

	&:last-child {
		margin-bottom: 30px;

		.task-card {
			border-bottom-left-radius: 6px;
			border-bottom-right-radius: 6px;
		}
	}
}

.task-list-container {
	padding-left: 25px;
}
