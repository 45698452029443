form {
	.form-group {
		font-size: 0.9rem;
	}
	.radio label {
		font-family: $font;
	}
	.form-control {
		&:not(.form-control-plaintext):focus {
			@include box-shadow(0px 0px 0px 0.1rem rgba($theme, 0.25));
			border-color: $theme;
		}
	}
	.react-tel-input {
		margin-bottom: 5px;
		.form-control {
			border-color: #dfe1e4;
		}
		.form-control-lg {
			height: calc(1.8em + 1rem + 2px) !important;
			border-radius: 8px;
		}
		.flag-dropdown {
			border-color: #dfe1e4;
			border-radius: 8px 0px 0px 8px;
			.selected-flag {
				border-radius: 8px 0px 0px 8px;
			}
		}
	}
	.form-control-plaintext {
		cursor: default;
	}
	.form-control-plaintext:focus {
		outline: none;
		box-shadow: none;
		border: none;
	}
	.input-error {
		color: $red;
		text-align: left;
		height: 0.9rem;
		font-size: 0.9rem;
		font-style: italic;
	}
	.input-smallnote {
		font-style: italic;
	}
}

.form-group.has-error {
	& > label {
		color: $red;
		opacity: 1 !important;
	}
	.form-control,
	.form-control-plaintext {
		border-bottom: 2px solid $red;
		&:focus {
			border-bottom: 2px solid $red;
		}
	}
}

.form-group,
.form-group-wrapper {
	position: relative;
	line-height: 1.1;
	& > label {
		font-size: 80%;
		margin-bottom: 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
	}
	.form-control,
	.form-control-plaintext {
		@include material-ui-input;
		padding-top: 0.8rem;
		font-size: $input-font-size;
		&:focus {
			border-bottom: 2px solid $theme;
		}
		&:focus,
		&.react-select-selected,
		&:not(:placeholder-shown) {
			box-shadow: none !important;
			~ label {
				@include material-ui-label($active: true);
			}
		}
		& ~ label,
		&[class*=" css-"][class$="-container"]:not(.react-select-selected) + label {
			@include material-ui-label($active: false);
		}
		&[class*=" css-"][class$="-container"] {
			border-bottom: 0px;
		}
	}
	.react-datepicker-wrapper {
		~ label {
			@include material-ui-label($active: false);
		}
		&.datepicker-selected {
			~ label {
				@include material-ui-label($active: true);
			}
		}
	}
	.form-control-plaintext,
	[disabled] {
		@include disabled-input;
	}
	.input-error {
		position: absolute;
	}
}

.form-group-wrapper {
	border-bottom: 2px solid #dfe1e4;
	.form-control {
		border-bottom: 0;
		margin-bottom: 0;
	}
	.input-postfix {
		background: white;
		padding-left: 1rem;
		padding-top: 1rem;
		font-size: $input-font-size;
	}
}

div[class*="css-"][class$="-multiValue"] {
	height: 22px;
	display: flex;
	align-items: center;
}

#elevatorModelForm {
	.custom-col {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 2%;
		max-width: 2%;
		font-size: 1.2rem;
		color: #404040;
	}

	.custom-col-btn {
		width: 4%;
		max-width: 4%;
	}
}

.table-search-input {
	margin-bottom: 0;

	.form-control {
		border-bottom: none;
		padding-top: 0;
		padding-left: 15px;
		padding-right: 15px;
		margin-bottom: 0;
	}

	.form-control:focus {
		border-bottom: none;
	}
}

.login-input-container {
    width: 100%;
    position: relative;
    background-color: white;
    border-radius: 3px;
    padding: 4px 8px 4px 35px;    
    border: 1px solid #f2f2f2;

    &.has-error {
        border-color: red;

        .icon-container svg {
            color: red;
        }
    }

    &.disabled {
        cursor: not-allowed;
    }

    box-shadow: 0px 5px 5px -2px rgba(122,122,122,0.75);
    -webkit-box-shadow: 0px 5px 5px -2px rgba(122,122,122,0.75);
    -moz-box-shadow: 0px 5px 5px -2px rgba(122,122,122,0.75);

    .icon-container {
        position: absolute;
        left: 12px;

        &.disabled {
            svg {
                color: #999999;
            }
        }
    }

    .custom-form-control {
        all: unset;
        width: 100%;

        &.disabled {
            color: #999999;
        }
    }

    .disabled-icon {
        position: absolute;
        right: 0;
        padding: 4px 8px 4px 8px;
        border-left: 1px solid #e6e6e6;
        background-color: #f2f2f2;
        top: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}


.input-container {
    width: 100%;
    position: relative;
    background:  rgba(119, 192, 241, 0.3);
    border-radius: 3px;
    padding: 4px 8px 4px 15px;    
    border: 1px solid #f2f2f2;
	margin-bottom: 20px;
    &.has-error {
        border-color: red;

        .icon-container svg {
            color: red;
        }
    }

    &.disabled {
        cursor: not-allowed;
    }

    box-shadow: 0px 5px 5px -2px rgba(122,122,122,0.75);
    -webkit-box-shadow: 0px 5px 5px -2px rgba(122,122,122,0.75);
    -moz-box-shadow: 0px 5px 5px -2px rgba(122,122,122,0.75);

    .icon-container {
        position: absolute;
        left: 12px;

        &.disabled {
            svg {
                color: #999999;
            }
        }
    }

    .custom-form-control {
        all: unset;
        width: 100%;
		color: #000000;
        &.disabled {
            color: #000000;
        }
    }

    .disabled-icon {
        position: absolute;
        right: 0;
        padding: 4px 8px 4px 8px;
        border-left: 1px solid #e6e6e6;
        background-color: #f2f2f2;
        top: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}

.custom-input-label {
    margin-bottom: 0;
    font-size: 12px;
    font-weight: bold;
	color: $smalltitle;
}

.user-img__container {
    aspect-ratio: 1;
    margin: 0 20%;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
    }
}

.scroll-component {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
}

