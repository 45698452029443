/*
Author: KurisuCodes
Author URI: https://github.com/kurisukodes
Description: ...
Version: 1.0
*/

.table-wrapper {
    border-radius: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    
    thead > tr > th, .thead > .tr > .th  {
        padding-top: 15px;
        position: sticky;
        top: 0;
        background-color: white;
    }
    
    .table {
        font-size: 0.9rem;

        .badge {
            font-size: 80%;
        }
    }
    
    .table {
        margin-bottom: 0;
    }
    
    .table-td-valign-middle {
        td, .td {
            white-space: pre;
        }

        td,
        th, .td, .th {
            border-color: #f2f2f2 !important;
            vertical-align: middle!important;
        }
    }
    
    .table {
        overflow: auto;
        padding-bottom: 7.5px;
        
    &::-webkit-scrollbar {
            width: 0.7rem;
            height: 0.7rem;
        }
        
        &::-webkit-scrollbar-thumb {
            background: $theme;
            border-radius: 8px;
            border: 3px solid darken($theme, 5%);
        }
        
        &::-webkit-scrollbar-thumb:hover {
            background: darken($theme, 5%);
        }
            
        .thead {
            .tr .th {
                vertical-align: bottom;
                font-weight: 600;
                font-size: 14px;

                &.-sort-desc{
                    box-shadow: inset 0 -3px 0 0 rgba(0,0,0,0.6);
                }

                &.-sort-asc{
                    box-shadow: inset 0 3px 0 0 rgba(0,0,0,0.6);
                }
                    
            }
        }

        .td, .th {
            white-space: nowrap!important;
            text-overflow: ellipsis;
            overflow: hidden;
            padding: 8px;
        }

        .th {
            padding: 8px;
        }

        .td:not(:last-child) {
            padding: 8px;
        }

        .tbody{
            font-size: 13px;
            padding: 5px;

            .tr {
                border: 1.5px solid #ccc;
                border-radius: 5px;
                box-shadow: 0px 0px 3px 0px rgba(179,179,179,0.75);
                -webkit-box-shadow: 0px 0px 3px 0px rgba(179,179,179,0.75);
                -moz-box-shadow: 0px 0px 3px 0px rgba(179,179,179,0.75);

                &:not(:last-child) {
                    margin-bottom: 10px;
                }
            }
        }
        
        &.-sticky{
            [data-sticky-last-left-td] {
                background-color: $white;
            }
                
            [data-sticky-first-right-td] {
                background-color: $white;
            }
        }
    }
}

.table-topbar {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 10px;
        
    .filter-btn {
        &:not(:last-of-type){
            margin: auto 0 auto auto;
        }

        border-width: 1.5px;
        min-width: fit-content;

        @media (max-width: 767.9px) {
            margin: 0 0 0 auto;
        }
                
        &.text-theme{
            &:hover, &:focus{
                color: $theme;
                border-color: $theme;
            }
        }
                
        i + span {
            margin-left: 0.4rem;

            @media (max-width: 767.9px) {
                display: none;
            }
        }
    }
        
    .table-search {
        margin-top: 0.75rem;
        display: flex;
        border-radius: 10px;

        div.row {
            row-gap: 1rem;
        }
    }
            
    .sub-header{
        margin-top: 0.75rem;
        margin-left: auto;
    }
}
        
.table-bottombar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
                
    .table-entries {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 35px;
        margin-left: auto;
        margin-right: 10px;
            
        .entries-select {
            flex: 1;
            margin: 0px 15px;
            border-radius: 10px;
            box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.1);
        }
    }
                
    .table-page-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
            
        .btn-number {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
            box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.1);
            transition: all 0.5s;
            cursor: pointer;
                
            &:hover {
                transition: box-shadow 0.5s;
                box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.3);
            }
                
            width: 2rem;
            height: 2rem;
            font-size: 0.9rem;
            border-radius: 50%;
            
            &:not(:last-child) {
                margin-right: 5px;
            }
            
            &.active {
                color: white;
                background-color: $theme;
                    
                transition: all 0.5s;
            }
        }
                        
        .btn-round {
            i {
                font-size: 1rem;
            }

            &:not(:last-child) {
                margin-right: 2px;
            }

            &:hover .btn-number {
                color: white;
            }
        }
    }
}